<template>
    <form novalidate=true @submit.prevent="enviarFormulario">
        <div class="row mb-3" v-if="!isModal">
            <div class="col-6">
                <h2 class="text-dark">
                    {{ titulo }}
                </h2>
            </div>
		</div>
        <div class="row">
            <div class="row col-md-12">
                    <div class="col-md-4 ">
                        <div class="form-group">
                            <label for="name" class="form_label ">Nombre</label>
                            <input class="form-control" type="text" v-model="form.name" :class="{'is-invalid': validaciones.name.error}" required="required" maxlength="255" id="name">
                        </div>
                    </div>
            </div>
        </div>
        <div class="row mb-3" v-if="!isModal">
            <div class="col-12">
                <hr />
            </div>
            <div class="col-6 text-left">
                <button type="button" class="btn btn-outline-dark" title="Volver" @click="volver">
                    <font-awesome-icon icon="arrow-left" /> Volver
                </button>
            </div>
            <div class="col-6 text-right">
                <button type="submit" class="btn btn-success" title="Guardar" v-loading-btn="{loading: loading}" >
                    <font-awesome-icon icon="save" /> Guardar
                </button>
            </div>
        </div>
    </form>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft, faSave, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Validaciones } from '../../utils/funciones'

library.add([faArrowLeft, faSave, faPlus]);

export default {
    name: 'LocacionesForm',
    props: {
        save: {
            type: Boolean,
            default: false
        },
        isModal: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        save: function (value) {
            if(value) {
                this.enviarFormulario();
            }
        }
    },
    data: () => {
        return {
            titulo: 'Crear locación',
            loading: false,
            estaEditando: false,
            form: {
                id: null,
                name: null
            },
            readonly: true,
            validaciones: {
                name: {
                    error: false,
                    reglas: 'not-empty'
                }
            }
        }
    },
    methods: {
        volver() {
            this.$router.push({path: '/locaciones'});
        },
        enviarFormulario() {
            let self = this;

            self.loading = true;
            Validaciones.reset(self.validaciones);
            Validaciones.validar(self.form, self.validaciones);
            let tieneError = Validaciones.hasError(self.validaciones);

            if(!tieneError) {
                const data = {...self.form };

                let $promise = null;
                delete data.id;
                if(self.estaEditando) {
                    $promise = self.axios.put(`locations/${self.$route.params.id}`, data);
                } else {
                    $promise =  self.axios.post(`locations`, data);
                }
                
                $promise
                .then(function(response) {
                    if(response.error) {
                        self.loading = false;
                        self.$toastr.e(response.message, "Error al guardar locación");
                    } else {
                        self.loading = false;
                        self.$toastr.s("Locación guardada exitosamente", "Correcto");

                        if(!self.isModal) {
                            self.volver();
                        }
                    }
                })
                .catch(function (error) {
                    self.loading = false;
                    self.$toastr.e(error, "Exception");
                });
            } else {
                self.loading = false;
                self.$toastr.e("El formulario tiene errores, verifique", "Error");
            }
        }
    },
    beforeMount: function(){
		let self = this;

        if(self.$route.params.id) {
            self.estaEditando = true;
            self.titulo = 'Editar locación';

            self.axios.all([
                self.axios.get(`locations/${self.$route.params.id}`)
            ])
            .then(self.axios.spread(
                (dataEdificio) => {
                    self.form.id = dataEdificio.data.id;
                    self.form.name = dataEdificio.data.name;

                    self.readonly = false;
                }
            )).catch((err) => {
                self.$toastr.e(err, "Exception");
            });
        }
    }
}
</script>

<style>
</style>